<template>
  <div
    class="data-location-card d-flex"
    ref="container"
  >
    <m-map
      v-if="map"
      :geofences="mapGeofences"
      :pois="mapPois"
      :cities="cities"
      :metric="metric"
      :roles="roles"
      :layers="layers"
      :layer="layer!='pois' ? 'geofences' : layer"
      :controls="!report"
      :options="options"
      ref="map"
      class="fill-height"
      @zoom-end="onZoomEnd"
      @layer-change="onLayerChange"
    >
      <template v-slot:toolbar>
        <div 
          ref="location"
          class="view location"
        >
          <v-btn
            icon
            absolute
            top
            right
            width="48"
            height="43"
            class="toggle-location-btn rounded-l-0 rounded-r elevation-0 pl-1"
            @click="onToggleView({ view: 'location', toggle: !views.location.toggle })"
          >
            <v-icon small>
              {{ views.location.toggle ? icons.up : icons.down }}
            </v-icon>
          </v-btn>
          <v-expand-transition>
            <data-card
              :chart="list.chart"
              :metric="metric"
              :tab="layer"
              :loading="list.loading"
              :min-width="views.location.width"
              :max-height="views.location.height"
              :fit-height="fitHeight"
              :mini="list.loading||!views.location.toggle"
              outlined
              selectable
              @metric-change="onMetricChange"
              @tab-change="onLayerChange"
              @select-item="onItemSelected"
              @toggle="onToggleView"
            >
              <template v-slot:header>
                <slot 
                  v-show="city!=null"
                  name="title" 
                />
                <m-tabs
                  :value="layer"
                  :items="layers"
                  mandatory
                  tile
                  class="location-layer-selector"
                  @change="onLayerChange"
                />
                <v-divider />
              </template>
            </data-card>
          </v-expand-transition>
        </div>
      </template>
    </m-map>
    <data-card
      v-else
      :chart="list.chart"
      :metric="metric"
      :tab="layer"
      :loading="list.loading"
      :min-width="views.location.width"
      :max-height="views.location.height"
      :fit-height="fitHeight"
      :mini="list.loading||!views.location.toggle"
      :outlined="outlined"
      selectable
      @metric-change="onMetricChange"
      @tab-change="onLayerChange"
      @select-item="onItemSelected"
      @toggle="onToggleView"
    >
      <template v-slot:header>
        <slot 
          v-show="city!=null"
          name="title" 
        />
        <m-tabs
          :value="layer"
          :items="layers"
          mandatory
          tile
          class="location-layer-selector"
          @change="onLayerChange"
        />
        <v-divider />
      </template>
    </data-card>

    <v-btn
      v-if="map&&!report"
      icon
      absolute
      bottom
      right
      small
      elevation="1"
      class="map-btn rounded white grey--text text--darken-2 mb-2 mr-10"
      @click="toggleFullscreen"
    >
      <v-icon small>
        {{ fullscreen ? icons.fullscreenOff : icons.fullscreenOn }}
      </v-icon>
    </v-btn>
  </div>
</template>

<style>

  .data-location-card {
    position: relative;
    width: 100%;
    /* overflow: hidden; */
  }

  .data-location-card .view.location {
    position: absolute;
    top: 16px;
    left: 16px;
    min-width: 320px;
    /* overflow: hidden; */
  }

  .data-location-card .map-btn {
    /* will-change: transform; */
    transition: transform 250ms ease-in-out;
  }

  .data-location-card .toggle-location-btn {
    top: 0 !important;
    right: 4px !important;
    transform: translateX(100%);
  }

  .faded {
    opacity: .6;
  }
  
</style>

<script>
  // Icons
  import { mdiFormatListBulleted, mdiClose, mdiFullscreen, mdiFullscreenExit, mdiEye, mdiCityVariantOutline, mdiChevronLeft, mdiChevronUp, mdiChevronDown, mdiLayers } from '@mdi/js';

  export default {
    components: {
      mMap: () => import('@/components/mMap'),
      DataCard: () => import('@/components/DataCard'),
      MTabs: () => import('@/components/mTabs'),
    },

    props: {
      map: {
        type: Boolean,
        default: false
      },
      geofences: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: {},
            options: {},
          }
        }
      },
      pois: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: {},
            options: {},
          }
        }
      },
      campaign: {
        type: Object,
        default: () => null
      },
      metric: {
        type: String,
        default: null,
      },
      metrics: {
        type: Object,
        default: () => {
          return {
            audience: { 
              title: 'dashboard.audience.title',
              unit: 'dashboard.audience.unit',
            },
            impressions: { 
              title: 'dashboard.impressions.title',
              unit: 'dashboard.impressions.unit',
            },
            spent: { 
              title: 'dashboard.spent.title',
              unit: 'dashboard.spent.unit', // TODO: local currency?
            },
            cpm: { 
              title: 'dashboard.cpm.title',
              unit: 'dashboard.cpm.unit',
            },
            airtime: { 
              title: 'dashboard.airtime.title',
              unit: 'dashboard.airtime.unit',
            },
          }
        }
      },
      cities: {
        type: Object,
        default: () => {}
      },
      city: {
        type: Object,
        default: () => {}
      },
      layer: {
        type: String,
        default: null,
      },
      layers: {
        type: Array,
        default: () => [],
      },
      roles: {
        type: Array,
        default: () => [],
      },
      maxHeight: {
        type: [Number, String],
        default: undefined
      },
      maxWidth: {
        type: [Number, String],
        default: undefined
      },
      fitHeight: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: 'Bairros'
      },
      fullscreen: {
        type: Boolean,
        default: false
      },
      outlined: {
        type: Boolean,
        default: false
      },
      report: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      icons: {
        viewing: mdiEye,
        list: mdiFormatListBulleted,
        close: mdiClose,
        fullscreenOn: mdiFullscreen,
        fullscreenOff: mdiFullscreenExit,
        city: mdiCityVariantOutline,
        left: mdiChevronLeft,
        up: mdiChevronUp,
        down: mdiChevronDown,
        layers: mdiLayers
      },
      views: {
        location: {
          title: 'Geofences',
          city: null,
          toggle: true,
          width: undefined,
          height: undefined,
        },
      },
      resizer: {
        delay: 1000,
        resizing: false,
        timer: null
      }
    }),

    watch: {
      fullscreen () {
        this.onResize();
      },
      layer: {
        immediate: true,
        handler (layer) {
          this.onResize();
        }
      }
    },

    computed: {
      list () {
        const l = this.layer;
        const layer = _.find(this.layers, ['value', l]);
        const city = this.city;
        const title = [{ value: this.$t(layer.text) + (l=='cities'||_.isNil(layer)||_.isNil(city) ? '' : ' - '+city.title), id: l }];
        const data = { [l]: _.orderBy(_.clone(this.layerData), [this.metric], [this.metric=='cpm' ? 'asc' : 'desc']) }
        const loading = _.isNil(layer) || layer.loading;
        console.log('loading', l, loading);
        return {
          chart: {
            type: 'SimpleTable',
            headers: [
              {
                text: title,
                align: 'left',
                value: 'title',
                key: 'title',
                type: 'text',
              },
              {
                text: this.$t(this.metrics[this.metric].unit),
                align: 'right',
                key: 'value',
                type: 'value',
              },
            ],
            data,
          },
          loading
        }
      },

      layerData () {
        const layer = this.layer;
        const city = this.city;
        let data;
        if (layer=='cities'||city==null) {
          data = this.citiesData;
        }else{
          data = this[_.camelCase('city '+layer)];
        }
        console.log('layerData', _.camelCase('city '+layer), data);
        return data;
      },

      citiesData () {
        let data = {}; 
        const geofences = _.clone(this.geofences.data);
        data = _.reduce(geofences, (cities, g) => {
          const { audience, impressions, spent, cpm, airtime, city, state, country } = g;
          if (_.has(g, 'city')) {
            const id = g.city.id;
            if (!_.has(cities, id)) {
              cities[id] = {
                ...city,
                geofences: [g.id],
                state,
                country,
                audience,
                impressions, 
                spent, 
                cpm,
                airtime,
                city: null
              }
            }else{
              const city = cities[id];
              city.geofences.push(g.id);
              cities[id] = {
                ...city,
                audience: city.audience + audience, 
                impressions: city.impressions + impressions, 
                spent: city.spent + spent, 
                cpm: _.mean([city.cpm, cpm]),
                airtime: city.airtime + airtime,
              }
            }
          }else{
            console.log('Unidentified geofence city', g);
          }
          return cities;
        }, {});
        return data;
      },

      cityZones () {
        let data = {};
        const city = this.city;
        if (city!=null) {
          const geofences = _.clone(this.cityGeofences);
          data = _.reduce(geofences, (zones, g) => {
            const { audience, impressions, spent, cpm, airtime, city, state, country } = g;
            if (_.has(g, 'zone')) {
              const id = g.zone.id;
              if (!_.has(zones, id)) {
                zones[id] = {
                  ...g.zone,
                  geofences: [g.id],
                  city,
                  state,
                  country,
                  audience,
                  impressions, 
                  spent, 
                  cpm,
                  airtime,
                }
              }else{
                const zone = zones[id];
                zone.geofences.push(g.id);
                zones[id] = {
                  ...zone,
                  audience: zone.audience + audience, 
                  impressions: zone.impressions + impressions, 
                  spent: zone.spent + spent, 
                  cpm: _.mean([zone.cpm, cpm]),
                  airtime: zone.airtime + airtime,
                }
              }
            }else{
              console.log('Unidentified geofence zone', g);
            }
            return zones;
          }, {});
        }
        return data;
      },

      cityGeofences () {
        let data = {};
        const city = this.city;
        if (city!=null) {
          const geofences = _.clone(this.geofences.data);
          data = _.pickBy(geofences, ['city.id', city.id]);
        }
        return data;
      },

      cityPois () {
        let data = [];
        const city = this.city;
        if (city!=null) {
          const pois = _.clone(this.pois.data);
          data = _.filter(pois, ['city.id', city.id]);
        }
        return data;
      },

      mapGeofences () {
        const geofences = _.clone(this.geofences);
        const layer = this.layer;
        const data = layer=='pois' ? {} : this.layerData;
        const toggle = layer!='pois';
        const type = layer=='pois' ? null : layer;
        return { ...geofences, toggle, type, data }
      },

      mapPois () {
        const pois = _.clone(this.pois);
        const data = this.cityPois;
        const layer = this.layer;
        const toggle = layer=='pois';
        return { ...pois, toggle, data };
      },

      options () {
        return {
          padding: {
            top: 36,
            left: this.views.location.toggle ? this.views.location.width : 36,
            bottom: 36,
            right: 36,
          }
        }
      }
    },
    
    methods: {

      onToggleView (p) {
        console.log(p)
        this.views[p.view].toggle = p.toggle;
      },

      onCityChange (id) {
        this.$emit('city-change', id);
      },

      onMetricChange (m) {
        this.$emit('metric-change', m);
      },

      onLayerChange (layer) {
        if (layer!=this.layer) {
          this.$emit('layer-change', layer);
        }else{
          this.onToggleView({ view: 'location', toggle: !this.views.location.toggle });
        }
      },

      onItemSelected (item) {
        if (this.layer=='cities') {
          this.onCityChange(item);
        }else{
          this.$emit('select-item', item);
        }
      },
      onZoomEnd (layer) {
        this.$emit('zoom-end', layer);
      },

      toggleFullscreen () {
        this.$emit('toggle-fullscreen');
      },

      onResize () {
        if (_.endsWith(this.maxHeight, 'vh')) {
          this.views.location.height = this.maxHeight;
          this.views.location.width = this.maxWidth;
          return;
        }
        if (!this.resizer.resizing&&_.has(this.$refs, 'container')&&_.has(this.$refs, 'location')) {
          this.resizer.resizing = true;
          const container = this.$refs.container.getBoundingClientRect();
          const location = this.$refs.location.getBoundingClientRect();
          this.views.location.height = container.height - 64;
          this.views.location.width = location.width;
          this.resizer.resizing = false;
          this.resizer.timer = clearTimeout(this.resizer.timer);
          console.log('height', this.views.location.height);
        }else{
          this.resizer.timer = setTimeout(($) => {
            $.onResize();
          }, this.resizer.delay, this);
        }
      }
    },

    mounted () {
      this.onResize();
    },
    beforeDestroy () {
      if (this.resizer.timer!=null) this.resizer.timer = clearTimeout(this.resizer.timer);
    },
  }
</script>